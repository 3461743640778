<!-- eslint-disable no-undef -->
<script>
import { debounce } from "debounce"
const API_KEY = "AIzaSyD74F2TrbVff9cQbP1_eGukqRWrc3RIMeg"
export default {
  name: "AddressPicker",
  props: {
    label: {
      default: "Address",
    },
    placeholder: {
      default: "Address",
    },
    value: {
      default: "",
    },
    inlineLabel: {
      default: false,
    },
    onlyAu: {
      default: false,
    },
    inputType: {
      default: "text",
      type: String,
      validator: function (val) {
        return ["object", "text"].includes(val)
      },
    },
  },
  data() {
    return {
      predictions: [],
      autocompleteService: null,
      placesService: null,
    }
  },
  methods: {
    async initAutocompleteAddress() {
      await this.$loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
      )
      this.autocompleteService = new google.maps.places.AutocompleteService()
    },
    async getAddressDetails(placeId) {
      return new Promise((resolve) => {
        if (!placeId) return resolve(null)
        const service = new google.maps.places.PlacesService(
          document.createElement("div")
        )
        service.getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const address = {
                subpremise: "",
                streetNumber: "",
                streetName: "",
                streetFull: "",
                locality: "",
                stateLong: "",
                stateShort: "",
                country: "",
                postcode: "",
              }
              for (const component of place?.address_components || []) {
                const componentType = component.types[0]
                switch (componentType) {
                  case "subpremise":
                    address.subpremise = component.long_name
                    break
                  case "street_number":
                    address.streetNumber = component.long_name
                    break
                  case "route":
                    address.streetName = component.long_name
                    break
                  case "locality":
                    address.locality = component.long_name
                    break
                  case "administrative_area_level_1":
                    address.stateLong = component.long_name
                    address.stateShort = component.short_name
                    break
                  case "country":
                    address.country = component.long_name
                    break
                  case "postal_code":
                    address.postcode = component.long_name
                    break
                }
              }
              address.streetFull = `${
                address.subpremise ? `${address.subpremise}/` : ``
              }${address.streetNumber ? `${address.streetNumber} ` : ""}${
                address.streetName
              }`
              resolve({ ...address })
            } else resolve(null)
          }
        )
      })
    },
    handleInput(text) {
      if (text.length > 7) this.debouncedGetPredictions(text)
      else this.predictions = []
      if (this.inputType === "object")
        this.$emit("input", {
          street: text,
          suburb: this.value.suburb,
          state: this.value.state,
          postcode: this.value.postcode,
          country: this.value.country,
        })
      else this.$emit("input", text)
    },
    async handleSelect(prediction) {
      this.predictions = []
      const details = await this.getAddressDetails(prediction.place_id)
      if (this.inputType === "object")
        this.$emit("input", {
          street: details.streetFull,
          suburb: details.locality,
          state: {
            text: details.stateLong,
            value: details.stateShort.toLowerCase(),
          },
          postcode: details.postcode,
          country: details.country,
        })
      else
        this.$emit(
          "input",
          `${details.streetFull}, ${details.locality}, ${details.stateShort} ${details.postcode}, ${details.country}`
        )
    },
    getPredictions(text) {
      const options = {
        input: text,
        componentRestrictions: { country: "au" },
      }
      this.autocompleteService.getPredictions(options, (predictions) => {
        if (predictions) {
          this.predictions = predictions
          document.addEventListener("click", () => (this.predictions = []))
        }
      })
    },
    debouncedGetPredictions: debounce(function (text) {
      this.getPredictions(text)
    }, 700),
  },
  mounted() {
    this.initAutocompleteAddress()
  },
  computed: {
    textValue: {
      get() {
        return this.inputType === "object" ? this.value.street : this.value
      },
      set(v) {
        if (this.inputType === "object") this.$set(this.value, "street", v)
        else this.$set(this.value, v)
      },
    },
  },
}
</script>
<template>
  <div class="address-container">
    <h3
      class="mb-4"
      v-if="!inlineLabel"
    >
      {{ label }}
    </h3>
    <v-text-field
      :value="textValue"
      @input="handleInput"
      v-bind="$attrs"
      :label="inlineLabel ? label : ''"
      :placeholder="placeholder"
    />
    <v-list
      v-if="predictions.length"
      id="suggestions"
      class="elevation-3"
      :class="{ 'about-you': inputType }"
    >
      <v-list-item
        v-for="(prediction, index) in predictions"
        :key="index"
        @click="handleSelect(prediction)"
      >
        <v-list-item-content>
          <v-list-item-title
            v-html="prediction.description"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
#suggestions {
  position: absolute;
  z-index: 2;
  background-color: white;
  border-radius: 0 0 8px 8px;
  top: 99px;
  width: 100%;
  &.about-you {
    top: 64px;
  }
}

.address-container {
  position: relative;
}
</style>
