<template>
  <v-input :error-messages="errorMessage">
    <div class="d-flex justify-start">
      <v-text-field
        class="day-field mr-4 text-center"
        v-model="localDay"
        label="Day"
        placeholder="DD"
        background-color="white"
        outlined
        ref="day"
        maxlength="2"
        @keypress="isNumber"
        @paste="isNumber"
        :error="!!errorMessage"
        validate-on-blur
        :rules="rules.date"
        hide-details
        :disabled="disabled"
      ></v-text-field>
      <v-text-field
        class="month-field mr-4"
        v-model="localMonth"
        label="Month"
        placeholder="MM"
        background-color="white"
        outlined
        ref="month"
        maxlength="2"
        @keypress="isNumber"
        @paste="isNumber"
        :error="!!errorMessage"
        validate-on-blur
        :rules="rules.month"
        hide-details
        :disabled="disabled"
      ></v-text-field>
      <v-text-field
        class="year-field"
        v-model="localYear"
        label="Year"
        placeholder="YYYY"
        background-color="white"
        outlined
        ref="year"
        @keypress="isNumber"
        @paste="isNumber"
        maxlength="4"
        validate-on-blur
        :rules="rules.year"
        :error="!!errorMessage"
        hide-details
        :disabled="disabled"
      ></v-text-field>
    </div>
  </v-input>
</template>

<script>
import { size } from "lodash"
import moment from "moment"

export default {
  name: "DateField",
  props: [
    "day",
    "month",
    "year",
    "valid",
    "disabled",
    "inFuture",
    "inPastNotDob",
    "isAdult",
    "notRequired",
  ],
  created() {
    this.validateDate()
  },
  data: function () {
    return {
      errorMessage: null,
      rules: {
        date: [
          (v) => v?.length === 2 || "Must be 2 digit number",
          (v) => (v <= 31 && v >= 1) || "Must be 1 to 31",
        ],
        month: [
          (v) => v?.length === 2 || "Must be 2 digit number",
          (v) => (v <= 12 && v >= 1) || "Must be 1 to 12",
        ],
        year: [(v) => v?.length === 4 || "Must be 4 digit number"],
      },
    }
  },
  watch: {
    localDay() {
      this.validateDate()
    },
    localMonth() {
      this.validateDate()
    },
    localYear() {
      this.validateDate()
    },
  },
  computed: {
    localValid: {
      get() {
        return this.valid
      },
      set(val) {
        this.$emit("update:valid", val)
      },
    },
    localDay: {
      get() {
        return this.day
      },
      set(val) {
        let digits = val.match(/\d+/g)

        if (digits) {
          let n = digits.join([])
          if (n.length === 2) {
            this.$refs.month.focus()
          }
          this.$emit("update:day", n)
        } else {
          this.$emit("update:day", "")
        }
      },
    },
    localMonth: {
      get() {
        return this.month
      },
      set(val) {
        let digits = val.match(/\d+/g)

        if (digits) {
          let n = digits.join([])
          if (n.length === 2) {
            this.$refs.year.focus()
          }
          this.$emit("update:month", n)
        } else {
          this.$emit("update:month", "")
        }
      },
    },
    localYear: {
      get() {
        return this.year
      },
      set(val) {
        let digits = val.match(/\d+/g)

        if (digits) {
          let n = digits.join([])
          this.$emit("update:year", n)
        } else {
          this.$emit("update:year", "")
        }
      },
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    setErrorMessage(error) {
      this.errorMessage = error
    },
    validateDate() {
      if (
        this.notRequired &&
        !this.localDay &&
        !this.localMonth &&
        !this.localYear
      ) {
        this.localValid = true
        this.errorMessage = null
        return
      }
      if (this.inPastNotDob) this.validateInPastNotDob()
      else if (this.inFuture) this.validateInFuture()
      else if (this.isAdult) this.validateIsAdult()
      else {
        this.localValid = true
        this.errorMessage = null
        return
      }
    },
    validateInPastNotDob() {
      const dayLength = size(this.localDay)
      const monthLength = size(this.localMonth)
      const yearLength = size(this.localYear)
      if (dayLength === 2 && monthLength === 2 && yearLength === 4) {
        const formattedDate = `${this.localDay}/${this.localMonth}/${this.localYear}`
        const inputDate = moment(formattedDate, "DD/MM/YYYY")
        if (!inputDate.isValid()) {
          this.localValid = false
          this.errorMessage = "Invalid date"
        } else if (inputDate.isBefore(moment())) {
          this.localValid = true
          this.errorMessage = null
        } else {
          this.localValid = false
          this.errorMessage = "Date must be in the past."
        }
      } else {
        this.localValid = false
        this.errorMessage = null
      }
    },
    validateInFuture() {
      const dayLength = size(this.localDay)
      const monthLength = size(this.localMonth)
      const yearLength = size(this.localYear)
      if (dayLength === 2 && monthLength === 2 && yearLength === 4) {
        const formattedDate = `${this.localDay}/${this.localMonth}/${this.localYear}`
        const thresholdDate = moment().add(100, "years")
        const inputDate = moment(formattedDate, "DD/MM/YYYY")
        if (!inputDate.isValid()) {
          this.localValid = false
          this.errorMessage = "Invalid date"
        } else if (
          inputDate.isAfter(moment()) &&
          inputDate.isBefore(thresholdDate)
        ) {
          this.localValid = true
          this.errorMessage = null
        } else {
          this.localValid = false
          this.errorMessage =
            "Date must be within the next 100 years and in the future."
        }
      } else {
        this.localValid = false
        this.errorMessage = null
      }
    },
    validateIsAdult() {
      const dayLength = size(this.localDay)
      const monthLength = size(this.localMonth)
      const yearLength = size(this.localYear)
      if (dayLength === 2 && monthLength === 2 && yearLength === 4) {
        const formattedDate = `${this.localDay}/${this.localMonth}/${this.localYear}`
        const thresholdDate = moment().subtract(18, "years")
        const inputDate = moment(formattedDate, "DD/MM/YYYY")
        if (!inputDate.isValid()) {
          this.localValid = false
          this.errorMessage = "Invalid date"
        } else if (thresholdDate.isAfter(inputDate)) {
          this.localValid = true
          this.errorMessage = null
        } else {
          this.localValid = false
          this.errorMessage = "Must be 18 years old."
        }
      } else {
        this.localValid = false
        this.errorMessage = null
      }
    },
  },
}
</script>

<style>
.day-field {
  max-width: 70px;
}
.month-field {
  max-width: 70px;
}
.year-field {
  max-width: 140px;
}
</style>
